<template>
  <div>
    <img :src="'https://lppmitb.wbsantosa.com/sdg/' + img + '.svg'" aria-expanded="true"
      style="margin-right: -20px;margin-bottom: 5px;;" @click="loadSDG(img)">
  </div>
</template>

<script>

export default {
  data() {
    return {
      isHovered: false
    };
  },
  props: {
    img: {}
  },
  computed: {
    imageSource() {
      return require(`@/assets/sdg/${this.img}.svg`);
    }
  },
  emits: ['loadSDG'],
  methods: {
    loadSDG(val) {
      this.$emit('loadSDG', val)
    }
  }

}
</script>

<style></style>